import { Slot, Slottable } from '@radix-ui/react-slot';
import { clsx } from 'clsx';
import { ComponentPropsWithoutRef, forwardRef } from 'react';
import { buttonSpinner, buttonVariants } from './button.css';
import { iconButton, iconButtonSize } from './icon-button.css';
import { Spinner } from '../spinner/spinner.tsx';

type IconButtonProps = {
  inversed?: boolean;
  size?: 'small' | 'medium' | 'large';
  variant?: 'primary' | 'secondary' | 'tertiary';
  asChild?: boolean;
  loading?: boolean;
} & ComponentPropsWithoutRef<'button'>;

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  function ButtonComponent(
    {
      size = 'medium',
      variant = 'primary',
      className,
      children,
      inversed,
      asChild = false,
      type = 'button',
      loading = false,
      disabled = false,
      ...rest
    },
    ref,
  ) {
    const Component = asChild ? Slot : 'button';

    return (
      <Component
        ref={ref}
        type={type}
        data-inversed={inversed ? '' : undefined}
        className={clsx(
          iconButton,
          iconButtonSize[size],
          buttonVariants[variant],
          className,
        )}
        disabled={disabled || loading}
        {...rest}
      >
        {!loading ? (
          <Slottable>{children}</Slottable>
        ) : (
          <Spinner
            className={buttonSpinner}
            size={size === 'small' ? 16 : 20}
          />
        )}
      </Component>
    );
  },
);
